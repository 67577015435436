import axios from 'axios';
import { authHeader } from './auth-header';

import { URL_API } from './Commons';

class CategoriaService {

    async getAll() {
        return await axios.get(URL_API + 'categorias', { headers: authHeader() });
    }

    async getOne(id) {
        return axios.get(URL_API + 'categoria/' + id, { headers: authHeader() });
    }


}

export default new CategoriaService();