import axios from 'axios';
import {authHeader} from './auth-header';

import { URL_API } from './Commons';

class EnqueteService {

    async getAll() {
        return await axios.get(URL_API + 'enquetes', { headers: authHeader() });
    }

    async getOne(id) {
        return axios.get(URL_API + 'enquete/' + id, { headers: authHeader() });
    }

    insert(id, resposta) {
        return axios.post(URL_API + 'enqresp/' + id, resposta, { headers: authHeader() });
      }
    
    async getRespostaPorEnquete(id) {
        return await axios.get(URL_API + 'enqrespok/'+id, { headers: authHeader() });
    }

}

export default new EnqueteService();