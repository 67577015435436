<template>
  <div>
    <form v-if="!isAnswered">
      <ul class="list-group">
        <li class="list-group-item question" aria-current="true">
          {{ enquete.pergunta }}
        </li>
        <li v-if="enquete.opcaoa" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="a"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('a', enquete.opcaoa)"
          />
          {{ enquete.opcaoa }}
        </li>
        <li v-if="enquete.opcaob" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="b"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('b', enquete.opcaob)"
          />
          {{ enquete.opcaob }}
        </li>
        <li v-if="enquete.opcaoc" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="c"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('c', enquete.opcaoc)"
          />
          {{ enquete.opcaoc }}
        </li>
        <li v-if="enquete.opcaod" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="d"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('d', enquete.opcaod)"
          />
          {{ enquete.opcaod }}
        </li>
        <li v-if="enquete.opcaoe" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="e"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('e', enquete.opcaoe)"
          />
          {{ enquete.opcaoe }}
        </li>
        <li v-if="enquete.opcaof" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            value="f"
            aria-label="..."
            name="votoenquete"
            v-model="enqueteSelected"
            @change="changeSelect('f', enquete.opcaof)"
          />
          {{ enquete.opcaof }}
        </li>
        <li v-if="enquete.opcaog" class="list-group-item">
          <input
            class="form-check-input me-1"
            type="radio"
            v-model="enqueteSelected"
            @change="changeSelect('g', enquete.opcaog)"
            aria-label="..."
            name="votoenquete"
          />
          {{ enquete.opcaog }}
        </li>
        <li class="list-group-item text-center">
          <button class="btn btn-primary" type="button" @click="sendResp">
            Gravar meu Voto <i class="bi bi-check-square"></i>
          </button>
        </li>
      </ul>
    </form>
    <ul class="list-group" v-if="isAnswered">
      <li class="list-group-item question" aria-current="true">
        {{ enquete.pergunta }}
      </li>
      <li v-if="enquete.opcaoa" class="list-group-item">
        {{ enquete.opcaoa }}
        <div class="progress" :key="enqueteResult.a">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.a/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.a/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.a/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaob" class="list-group-item">
        {{ enquete.opcaob }}
        <div class="progress" :key="enqueteResult.b">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.b/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.b/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.b/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaoc" class="list-group-item">
        {{ enquete.opcaoc }}
        <div class="progress" :key="enqueteResult.c">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.c/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.c/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.c/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaod" class="list-group-item">
        {{ enquete.opcaod }}
        <div class="progress" :key="enqueteResult.d">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.d/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.d/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.d/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaoe" class="list-group-item">
        {{ enquete.opcaoe }}
        <div class="progress" :key="enqueteResult.e">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.e/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.e/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.e/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaof" class="list-group-item">
        {{ enquete.opcaof }}
        <div class="progress" :key="enqueteResult.f">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.f/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.f/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.f/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li v-if="enquete.opcaog" class="list-group-item">
        {{ enquete.opcaog }}
        <div class="progress" :key="enqueteResult.g">
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: '+(enqueteResult.g/enqueteResultTotal)*100+'%'"
            :aria-valuenow="(enqueteResult.g/enqueteResultTotal)*100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ parseFloat((enqueteResult.g/enqueteResultTotal)*100).toFixed(2) }} %
          </div>
        </div>
      </li>
      <li class="list-group-item text-center bg-success text-white">
        Obrigado por seu Voto <i class="bi bi-hand-thumbs-up"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import EnqueteService from "../services/enquete.service";

export default {
  name: "EnqueteComponentItem",
  props: ["enquete"],
  data: () => ({
    enqueteSelected: {},
    enqueteSelectedLetra: "",
    enqueteSelectedResposta: "",
    enqueteResult: { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0 },
    enqueteResultTotal: 0,
    isAnswered: false,
  }),
  mounted() {
    if (localStorage.getItem(this.enquete.id)) {
      this.isAnswered = true;
      this.loadResponse();
    } else {
      this.isAnswered = false;
    }
  },
  methods: {
    sendResp: function () {
      let objSend = {
        tb_enquete_id: this.enquete.id,
        resposta: this.enqueteSelectedResposta,
        resposta_letra: this.enqueteSelectedLetra,
        status: 1,
      };
      EnqueteService.insert(this.enquete.id, objSend).then((result) => {
        result;
        localStorage.setItem(this.enquete.id, "ok");
        this.loadResponse();
        this.isAnswered = true;
      });
    },
    changeSelect: function (newLeather, pergunta) {
      this.enqueteSelectedLetra = newLeather;
      this.enqueteSelectedResposta = pergunta;
    },
    loadResponse: function () {
      EnqueteService.getRespostaPorEnquete(this.enquete.id).then((resumo) => {        
        resumo.data.forEach((itemResumo) => {
          if (itemResumo.resposta_letra == "a") {
            this.enqueteResult.a = this.enqueteResult.a + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "b") {
            this.enqueteResult.b = this.enqueteResult.b + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "c") {
            this.enqueteResult.c = this.enqueteResult.c + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "d") {
            this.enqueteResult.d = this.enqueteResult.d + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "e") {
            this.enqueteResult.e = this.enqueteResult.e + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "f") {
            this.enqueteResult.f = this.enqueteResult.f + 1;
            this.enqueteResultTotal += 1;
          }
          if (itemResumo.resposta_letra == "g") {
            this.enqueteResult.g = this.enqueteResult.g + 1;
            this.enqueteResultTotal += 1;
          }
        });
      });
    },
  },
};
</script>

<style>
</style>